import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'

import { colors } from 'theme'

const Item = ({ id, type, title, logo }) => {
  let typeColor = '#F45B83'

  switch(type) {
  case 'UI/UX':
    typeColor = '#FB9E13'
    break
  case 'Mobile App':
    typeColor = '#CE5528'
    break
  default:
    typeColor = '#F45B83'
  }

  return <Wrapper logo={logo}>
    <div className='content'>
      <div className='type' style={{ backgroundColor: typeColor }}>{type}</div>
      <div className='imgWrapper'></div>
      <div className='title'>{title}</div>
      {id && <div className='view'>Details <FontAwesomeIcon icon={faAngleDoubleRight} /></div>}
      {id && <CustomLink to={`/projects/${id}`}></CustomLink>}
    </div>
  </Wrapper>
}

export default Item

const 
  Wrapper = styled.div`
    width: 100%;
    padding: 0 15px;
    margin-bottom: 30px;
    .content {
      padding: 50px 15px 35px;
      border-radius: 8px;
      overflow: hidden;
      height: 100%;
      box-shadow: 0px 0px 5px 0px rgba(37,39,77,0.2);
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .type {
        border-radius: 10px;
        padding: 5px 10px;
        font-size: 12px;
        color: #fff;
        font-weight: 600;
        position: absolute; top: 15px; right: 15px;
      }
      .imgWrapper {
        ${props => props.logo ? `
          width: 100%;
          max-width: calc(100% - 40px);
          height: 150px;
          background: url(${props.logo}) no-repeat center center transparent;
          background-size: contain;
          display: flex;
        ` : `
          width: 150px;
          height: 150px;
          background: ${colors.tertiary};
          opacity: 0.3;
          display: flex;
          border-radius: 50%;
        `}
      }
      .title {
        margin: 30px 0 10px;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        color: ${colors.secondary};
      }
      .view {
        font-weight: 600;
        color: ${colors.secondary};
        font-size: 13px;
        position: absolute; bottom: 15px; right: 15px;
      }
    }
    @media(min-width: 650px) {
      width: 50%;
    }
    @media(min-width: 1024px) {
      width: 33.3%;
    }
  `,
  CustomLink = styled(NavLink)`
    position: absolute;
    top: 0; left: 0;
    height: 100%; width: 100%;
    background: rgba(37,39,77,0.3);
    opacity: 0;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 1;
    }
  `
