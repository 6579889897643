import React from 'react'

import About from './About'
import Resume from './Resume'
import Qualifications from './Qualifications'

const Main = () => <React.Fragment>
  <About />
  <Resume />
  <Qualifications />
</React.Fragment>

export default Main
