import React from 'react'
import styled from 'styled-components'

import { Section, Container } from 'commons/styles'
import ContactForm from 'components/commons/ContactForm'

const Collaborate = () => <React.Fragment>
  <CustomSection>
    <Container maxWidth={768}>
      <Title>
        Interested in collaborating?<br />
        Leave me a message and I'll get back to you asap.
      </Title>
      <ContactForm />
    </Container>
  </CustomSection>
</React.Fragment>

export default Collaborate

const
  CustomSection = styled(Section)`
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(min-width: 991px) {
      padding-bottom: 200px;
    }
  `,
  Title = styled.div`
    font-size: 28px;
    text-align: center;
    margin-bottom: 50px;
  `
