import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

export default [
  {
    icon: faLinkedinIn,
    link: 'https://www.linkedin.com/in/niks0346/'
  },
  {
    icon: faFacebookF,
    link: 'https://www.facebook.com/nikz0346'
  },
  {
    icon: faInstagram,
    link: 'https://www.instagram.com/nikz0346/'
  },
  {
    icon: faEnvelope,
    link: 'mailto:nikbaidya0346@gmail.com'
  }
]
