import FelixLogo from './Felix/logo.svg'
import Felix from './Felix/felix.png'
import FelixBanner from './Felix/felix-banner.png'

import BFNLogo from './BookingForNepal/logo.png'

export default [
  {
    id: 1,
    type: 'Web App',
    title: 'Felix&Friends',
    description: 'Felix and Friends is a team of intelligent and creative problem solvers. Commencing a new venture with a client is primarily about creating open channels of communication and keeping project momentum throughout the duration. We believe a project should be a joyful experience for our clients.',
    technologies: ['wordpress', 'html', 'sass', 'jquery', 'mysql', 'acf'],
    logo: FelixLogo,
    image: Felix,
    banner: FelixBanner
  },
  {
    id: 2,
    type: 'Web App',
    title: 'BookingForNepal',
    description: 'Felix and Friends is a team of intelligent and creative problem solvers. Commencing a new venture with a client is primarily about creating open channels of communication and keeping project momentum throughout the duration. We believe a project should be a joyful experience for our clients.',
    technologies: ['react', 'styled_components', 'ruby', 'psql'],
    logo: BFNLogo,
    image: Felix,
    banner: FelixBanner
  },
  // {
  //   id: 1,
  //   type: 'UI/UX',
  //   title: 'UI UX Project',
  //   technologies: ['figma', 'fontawesome']
  // },
  // {
  //   id: 5,
  //   type: 'Mobile App',
  //   title: 'Mobile App Project No Link',
  //   technologies: ['react_native', 'psql', 'ruby', 'styled_components']
  // },
  // {
  //   id: 6,
  //   type: 'Web App',
  //   title: 'Web App Project',
  //   technologies: ['react', 'psql', 'ruby', 'styled_components']
  // },
  // {
  //   id: 3,
  //   type: 'Web App',
  //   title: 'Web App Project',
  //   technologies: ['react', 'psql', 'ruby', 'styled_components']
  // },
  // {
  //   id: 4,
  //   type: 'UI/UX',
  //   title: 'UI UX Project',
  //   technologies: ['react', 'psql', 'ruby', 'styled_components']
  // },
  // {
  //   id: 7,
  //   type: 'UI/UX',
  //   title: 'All Test Component',
  //   technologies: ['wordpress', 'jquery', 'html', 'sass', 'elementor', 'mysql', 'acf', 'expo', 'figma', 'fontawesome', 'react', 'react_native', 'ruby', 'psql', 'graphql', 'styled_components', 'figma']
  // },
]
