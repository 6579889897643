import JQ from 'assets/images/technologies/jq.png'
import ACF from 'assets/images/technologies/acf.png'
import SASS from 'assets/images/technologies/sass.png'
import WP from 'assets/images/technologies/wp.png'
import HTML from 'assets/images/technologies/html.png'
import MYSQL from 'assets/images/technologies/mysql.png'
import REACT from 'assets/images/technologies/react.png'
import PSQL from 'assets/images/technologies/psql.png'
import ROR from 'assets/images/technologies/rails.png'
import EXPO from 'assets/images/technologies/expo.png'
import FONTAWESOME from 'assets/images/technologies/fontawesome.png'
import FIGMA from 'assets/images/technologies/figma.png'
import GRAPHQL from 'assets/images/technologies/graphql.png'
import ELEMENTOR from 'assets/images/technologies/elementor.png'

export default ({
  wordpress: {
    title: 'WordPress',
    logo: WP
  },
  react: {
    title: 'ReactJS',
    logo: REACT
  },
  react_native: {
    title: 'ReactJS Native',
    logo: REACT
  },
  ruby: {
    title: 'Ruby on Rails',
    logo: ROR
  },
  psql: {
    title: 'PostgreSQL',
    logo: PSQL
  },
  styled_components: {
    title: 'Styled Components',
    logo: null
  },
  jquery: {
    title: 'jQuery',
    logo: JQ
  },
  html: {
    title: 'HTML5',
    logo: HTML
  },
  sass: {
    title: 'SASS',
    logo: SASS
  },
  mysql: {
    title: 'MySQL',
    logo: MYSQL
  },
  acf: {
    title: 'ACF',
    logo: ACF
  },
  graphql: {
    title: 'GraphQL',
    logo: GRAPHQL
  },
  elementor: {
    title: 'Elementor',
    logo: ELEMENTOR,
  },
  expo: {
    title: 'Expo.io',
    logo: EXPO
  },
  figma: {
    title: 'Figma',
    logo: FIGMA
  },
  fontawesome: {
    title: 'Font Awesome',
    logo: FONTAWESOME
  }
}
)