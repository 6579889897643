import React from 'react'
import styled from 'styled-components'

import BackgroundImage from 'assets/images/page-header.jpg'
import { Container } from '../styles'
import { colors } from 'theme'

const PageHeader = ({ title, subTitle }) => <Wrapper>
  <Container>
    <div className='content'>
      {subTitle && <h5>{subTitle}</h5>}
      {title && <h2>{title}</h2>}
    </div>
    <div className='overlay'></div>
  </Container>
</Wrapper>

const 
  Wrapper = styled.section`
    min-height: 300px;
    background: url(${BackgroundImage}) no-repeat center bottom rgb(255,255,255);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px;
    .content {
      position: relative;
      z-index: 1;
      h2 {
        font-size: 60px;
        text-transform: uppercase;
        color: ${colors.primary};
      }
      h5 {
        font-size: 18px;
        font-family: 'Montserrat', helvetica;
        font-weight: 300;
        line-height: 1;
        margin-bottom: -10px;
        margin-left: 5px;
      }
    }
    .overlay {
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(248,250,252,0) 100%);
    }
    @media(min-width: 991px) {
      padding: 40px 20px;
      min-height: 400px;
      .content {
        h2 {
          font-size: 80px;
        }
      }
    }
  `

export default PageHeader
