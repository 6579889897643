import React from 'react'

import { colors } from 'theme'

const BrandLogo = ({ fillColor = colors.primary, strokeColor = colors.primary, width = 200, height = 248, className = '', strokeWidth = 0 }) => <svg className={className} width={width} height={height} viewBox="0 0 202 239" fill="none">
  <path d="M24 182.909V52L199.065 247.325L49.2814 113.991L24 182.909Z" fill={fillColor} stroke={strokeColor} stroke-width={strokeWidth} />
  <path d="M175.065 71.4156L175.065 202.325L1.34417e-05 7.00005L149.784 140.333L175.065 0L175.065 71.4156Z" fill={fillColor} stroke={strokeColor} stroke-width={strokeWidth} />
</svg>


export default BrandLogo
