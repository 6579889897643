import React from 'react'

import About from './About'
import Maintenance from '../404'

const Main = () => <React.Fragment>
  <About />
  <Maintenance wrapperStyles={{ padding: '150px 0 200px', minHeight: 'auto' }} header='SORRY FOR THE INCONVENIENCE' />
</React.Fragment>

export default Main
