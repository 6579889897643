export const education = [
  {
    name: "ST. Xavier's School, Jawalakhel",
    post: 'SLC',
    year: 'Batch of 2008'
  },
  {
    name: 'Xavier Academy, Lazimpat',
    post: 'CBSE +2',
    year: 'Batch of 2010'
  },
  {
    name: 'Kathmandu University, Dhulikhel',
    post: 'Bachelors in Computer Engineering',
    year: 'Batch of 2014'
  },
  {
    name: 'The British College, Thapathali',
    post: 'Master of Science in Information Technology',
    year: 'Batch of 2019'
  }
]

export const work = [
  {
    name: 'EBPearls, Kupondole',
    post: 'Internship',
    year: '2014 - 2017'
  },
  {
    name: 'EBPearls, Kupondole',
    post: 'Internship',
    year: '2014 - 2017'
  },
  {
    name: 'EBPearls, Kupondole',
    post: 'Internship',
    year: '2014 - 2017'
  },
  {
    name: 'EBPearls, Kupondole',
    post: 'Internship',
    year: '2014 - 2017'
  },
  {
    name: 'EBPearls, Kupondole',
    post: 'Internship',
    year: '2014 - 2017'
  }
]