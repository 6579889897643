import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import ERRIMAGE from 'assets/images/construction.png'

import { colors } from 'theme'

const MaintenancePage = ({ wrapperStyles = {}, header = 'I AM COMING SOON' }) => <Wrapper style={wrapperStyles}>
  <Content>
    <img src={ERRIMAGE} alt='underconstruction' width='350' height='220' />
    <h5>{header}</h5>
    <h2>This website is under maintenance!</h2>
    <p>For futher queries, <Link to='/contact'>contact me</Link>.</p>
  </Content>
</Wrapper>

export default MaintenancePage

const 
  Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding: 100px 40px;
    position: relative;
  `,
  Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    img {
      max-width: 350px;
      width: 100%;
      display: block;
      margin-bottom: 50px;
    }
    h5 {
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 30px;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      font-weight: 100;
      a {
        color: ${colors.primary};
        font-weight: 400;
        text-decoration: none;
      }
    }
  `