import React, { useState } from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faGraduationCap, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

import { Section, Container } from 'commons/styles'
import { colors } from 'theme'

import { education, work } from '.data/qualifications'

const Qualifications = () => {
  const [selected, updateSelection] = useState('education')

  let data = selected === 'work' ? work : education

  return <Section bgColor='#fafafa'>
    <Container>
      <TabsWrapper>
        <Tab active={selected === 'education'}>
          <span onClick={() => updateSelection('education')}>Education<FontAwesomeIcon icon={faGraduationCap} /></span>
        </Tab>
        <Tab active={selected === 'work'}>
          <span onClick={() => updateSelection('work')}><FontAwesomeIcon icon={faBriefcase} />Work</span>
        </Tab>
      </TabsWrapper>
      <TabContent>
        {data.map(i => <Period>
          <div>
            <span className='name'>{i.name}</span>
            <span className='post'>{i.post}</span>
            <span className='year'><FontAwesomeIcon icon={faCalendarAlt} /> {i.year}</span>
          </div>
        </Period>)}
      </TabContent>
    </Container>
  </Section>
}

export default Qualifications

const 
  TabsWrapper = styled.div`
    display: flex;
    flex-direction: row;
  `,
  Tab = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    color: ${colors.tertiary};
    opacity: 0.8;
    font-size: 18px;
    font-weight: 400;
    transition: opacity 0.3s ease, color 0.3s ease;
    &:first-of-type {
      justify-content: flex-end;
    }
    span {
      cursor: pointer;
      padding: 5px 10px;
      svg {
        margin: 0 10px;
      }
    }
    ${props => props.active && `
      opacity: 1;
      color: ${colors.primary};
    `}
    &:hover {
      opacity: 1;
    }
    @media(min-width: 768px) {
      font-size: 22px;
    }
  `,
  TabContent = styled.div`
    margin-top: 30px;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 2px; height: calc(100% - 32px);
      position: absolute; 
      top: 7px; left: 0; margin-left: -1px;
      background: ${colors.primary};
    }
    @media(min-width: 768px) {
      &::before {
        left: 50%;
      }
    }
  `,
  Period = styled.div`
    display: flex;
    flex-direction: row;
    & > div {
      position: relative;
      padding: 0 25px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      & > span {
        font-size: 15px;
        color: ${colors.secondary};
      }
      .name {
        font-weight: 600;
        font-size: 18px;
      }
      .post {
        font-style: italic;
        opacity: 0.8;
      }
      .year {
        font-size: 13px;
        margin-top: 12px;
        opacity: 0.5;
      }
      &::before {
        content: ''; display: block;
        width: 14px; height: 14px; border-radius: 100%;
        background: ${colors.primary};
        box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
        top: 7px; left: -7px;
        position: absolute;
      }
    }
    @media(min-width: 768px) {
      & > div {
        width: 50%;
      }
      &:nth-of-type(2n) {
        flex-direction: row-reverse;  
      }
      &:nth-of-type(2n + 1) {
        & > div {
          text-align: right;
          &::before {
            left: none; left: unset; left: auto;
            right: -7px;
          }
        }
      }
    }
  `
