import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import Button from 'commons/Button'
import { Section } from 'commons/styles'

import IMAGE from 'assets/images/success.svg'

const Success = ({ history }) => <Wrapper>
    <Content>
      <img src={IMAGE} alt='Success' />
      <h4>Thanks for being awesome!</h4>
      <p>I've received your message and will get back to you as soon as possible.</p>
      <Button height={60} onClick={() => history.replace('/')}><FontAwesomeIcon icon={faChevronLeft} /> Back to Home</Button>
    </Content>
</Wrapper>

export default Success

const 
  Wrapper = styled(Section)`
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Content = styled.div`
    max-width: 600px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    img {
      max-width: 300px;
      width: 100%;
    }
    h4 {
      font-size: 24px;
      margin: 30px 0 20px;
    }
    button {
      margin-top: 30px;
      svg {
        font-size: 20px;
        margin-right: 10px;
      }
    }
    @media(min-width: 768px) {
      // padding-bottom: 120px;
      h4 {
        font-size: 32px;
      }
    }
  `
