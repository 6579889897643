import React from 'react'
import styled from 'styled-components'

import MockupImage from 'assets/images/mockup.png'
import { colors } from 'theme'

const Header = ({ title, description, type, image }) => <Wrapper>
  <div className='content'>
    <h2>{title}</h2>
    <div className='mockup'>
      <img src={image || MockupImage} alt={title} width='700' height='558' />
    </div>
    <p>{description}</p>
    <p style={{ marginTop: 10 }}><b>Project Type: </b>{type}</p>
  </div>
</Wrapper>

export default Header

const 
  Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 80px 20px;
    .content {
      max-width: 991px;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      h2 {
        font-size: 50px;
        line-height: 1.3;
        text-transform: uppercase;
        color: ${colors.primary};
        position: relative;
        padding-bottom: 5px;
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 150px;
          height 3px;
          background: ${colors.primary};
          bottom: 0; left: 50%; margin-left: -75px;
        }
      }
      p {
        margin-top: 50px;
        font-size: 22px;
        line-height: 1.7;
      }
      .mockup {
        img {
          width: 100%;
          max-width: 700px;
        }
      }
    }
    @media(min-width: 991px) {
      .content {
        h2 {
          font-size: 80px;
          line-height: 1.5;
        }
      }
    }
  `
