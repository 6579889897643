import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'

import { Section, Container } from 'commons/styles'
import Button from 'commons/Button'
import DeveloperImage from 'assets/images/developer.png'

import Resume from '.data/Resume.pdf'

const About = () => <React.Fragment>
  <Section style={{ paddingBottom: 0 }}>
    <Container>
      <AboutContent>
        <div className='container imageWrapper'>
          <img src={DeveloperImage} alt='Developer Image' />
        </div>
        <div className='container'>
          <h3>Hi, I'm Nikhil.</h3>
          <p>
            A Software Engineer with over 8 years of experince in UI/UX designs, web developments, mobile app developments producing high quality work and currently working as a software architect located in capital city of Nepal, Kathmandu.
          </p>
          <a href={Resume} without rel="noopener noreferrer" target="_blank" style={{ textDecoration: 'none' }}>
            <Button 
              style={{ marginTop: 20 }} 
              trailingIcon="picture_as_pdf" label="Resume"
            >
              <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: 10, fontSize: 20 }} />  
              Check out my Resume
            </Button>
          </a>
        </div>
      </AboutContent>
    </Container>
  </Section>
</React.Fragment>

export default About

const
  AboutContent = styled.div`
    h3 {
      font-size: 48px;
      margin-bottom: 20px;
    }
    p {
      font-weight: 100;
      font-size: 22px;
      line-height: 1.8;
    }
    .container.imageWrapper {
      display: flex;
      justify-content: flex-end;
      img {

        display: block;
        width: 100%;
        max-width: 500px;
      }
    }
    @media(min-width: 768px) {
      display: flex;
      flex-direction: row-reverse;
      & > .container {
        flex: 1;
        max-width: 50%;
      }
    }
  `
