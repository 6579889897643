import React from 'react'
import { get } from 'lodash'
import styled from 'styled-components'

import { Section, Container } from 'commons/styles'
import { colors } from 'theme'

import technologiesData from 'constants/technologies'

const Technologies = ({ technologies }) => <Section bgColor='#fafafa'>
  <Container>
    <SectionTitle>Technologies used for building this project.</SectionTitle>
    <Wrapper>
      {technologies.map(i => <Item>
        <div className='logo'>
          {get(technologiesData, i).logo && <img src={get(technologiesData, i).logo} alt={get(technologiesData, i).title} />}
        </div>
        <p>{get(technologiesData, i).title}</p>
      </Item>)}
    </Wrapper>
  </Container>
</Section>

export default Technologies

const 
  SectionTitle = styled.h4`
    text-align: center;
    font-size: 30px;
    color: ${colors.secondary};
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 80px;
  `,
  Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  `,
  Item = styled.div`
    width: 100%;
    padding: 15px;
    margin-bottom: 30px;
    text-align: center;
    .logo {
      width: 100%;
      height: 80px;
      margin-bottom: 15px;
      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
      }
    }
    p {
      color: ${colors.secondary};
      font-size: 20px;
      font-weight: 600;
      
    }
    @media(min-width: 480px) {
      width: 50%;
    }
    @media(min-width: 768px) {
      width: 33.3%;
    }
    @media(min-width: 1024px) {
      width: 25%;
    }
    @media(min-width: 1200px) {
      width: 20%;
    }
  `
