import React from 'react'
import { format } from 'date-fns'
import { NavLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAirbnb } from '@fortawesome/free-brands-svg-icons'

import { Container } from 'commons/styles'
import Button from 'commons/Button'
import { colors } from 'theme'

import socialLinks from '.data/sociallinks'
import BrandLOGO from 'commons/BrandLogo'

const Footer = () => {
  const history = useHistory()
  
  return <Wrapper>
    <Container>
      <StartCTA>
        <p>Interested in working together? We should queue up a chat. Leave me a message and I'll come back to you.</p>
        <CustomButton plainBorder onClick={() => history.push('/contact')}>Let's Chat <FontAwesomeIcon icon={faAirbnb} /></CustomButton>
      </StartCTA>
      <Content>
        <CustomNavLink to='/'>
          <BrandLOGO fillColor='#fff' width={70} height={80} />
          <p>Nikhil Baidya</p>
        </CustomNavLink>
        <SocialLinks>
          {socialLinks.map(i => <li>
            <a href={i.link} target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={i.icon} /></a>  
          </li>)}
        </SocialLinks>
        <CopyRight>
          Copyright © {format(new Date(), 'yyyy')} || Nikhil Baidya
        </CopyRight>
      </Content>
    </Container>
  </Wrapper>
}

export default Footer

const
  Wrapper = styled.footer`
    background: ${colors.primary};
    position: relative;
    z-index: 1;
    padding: 50px 0;
    @media(min-width: 768px) {
      padding: 150px 0 50px;
    }
  `,
  Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  CustomNavLink = styled(NavLink)`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 70px;
      display: block;
    }
    p {
      margin-top: 10px;
      font-size: 20px;
      color: #fff;
    }
  `,
  CopyRight = styled.div`
    color: #fff;
    opacity: 0.8;
    font-size: 13px;
  `,
  SocialLinks = styled.ul`
    margin: 30px 0 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    li {
      a {
        width: 40px;
        height: 40px;
        color: #fff;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff;
        border-radius: 50%;
        transition: all 0.3s ease;
        &:hover {
          background: #fff;
          color: ${colors.primary};
        }
      }
      margin: 0 10px;
    }
  `,
  StartCTA = styled.div`
    padding: 50px;
    border-radius: 10px;
    background: ${colors.secondary};
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    p {
      text-align: center;
      color: #fff;
      margin-bottom: 25px;
    }
    button {
      color: #fff;
    }
    @media(min-width: 768px) {
      padding: 50px 100px;
      margin-bottom: 0;
      position: absolute; top: -90px; left: 50%; transform: translateX(-50%); 
      max-width: 1024px;
      width: calc(100% - 40px);
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      p {
        margin-bottom: 0;
        text-align: left;
        max-width: 60%;
      }
    }
  `,
  CustomButton = styled(Button)`
    border-color: #fff;
    &:hover {
      background: #fff;
      color: ${colors.secondary};
    }
    svg {
      font-size: 24px;
      transform: rotate(90deg);
      margin-left: 10px;
    }
  `
