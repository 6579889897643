import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import ReactGA from 'react-ga'

import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAirbnb } from '@fortawesome/free-brands-svg-icons'

import Button from 'commons/Button'
import BrandLOGO from 'commons/BrandLogo'
import { colors } from 'theme'
import { Container } from '../styles'

const Header = () => {
  const [boxShadow, toggleBoxShadow] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const isContactPage = get(location, 'pathname') === '/contact'
  
  useScrollPosition(
    ({ currPos }) => {
      if(currPos.y < -100) toggleBoxShadow(true)
      else toggleBoxShadow(false)
    },
    [boxShadow]
  )

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development')
      ReactGA.pageview(get(history, 'location.pathname', '/') + get(history, 'location.search', ''))
  }, [history.location])

  return <Wrapper active={boxShadow}>
    <Container>
      <Content>
        <LogoLink to='/' active={boxShadow}>
          <BrandLOGO className='logoImg' width={60} height={70} /><span>ikhil Baidya</span>
        </LogoLink>
        <div className='buttons'>
          {!isContactPage && <CustomButton plainBorder onClick={() => history.push('/contact')}>Let's Chat <FontAwesomeIcon icon={faAirbnb} /></CustomButton>}
        </div>
      </Content>
    </Container>
  </Wrapper>
}

export default Header

const 
  Wrapper = styled.header`
    position: sticky;
    z-index: 2;
    top: 0;
    left: 0;
    background: #fff;
    transition: box-shadow 0.3s ease;
    ${props => props.active && `
      box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.35);
    `}
  `,
  Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  `,
  CustomButton = styled(Button)`
    svg {
      font-size: 24px;
      transform: rotate(90deg);
      margin-left: 10px;
    }
  `,
  LogoLink = styled(NavLink)`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: ${colors.primary};
    span {
      display: none;
    }
    .logoImg {
      display: block;
    }
    @media(min-width: 768px) {
      span {
        display: flex;
        margin-top: 10px;
        font-family: 'Proza Libre', sans-serif;
        letter-spacing: 1px;
        line-height: 1.1;
        font-size: 22px;
        opacity: 1;
        width: 0;
        transition: opacity 0.3s ease;
      }
      ${props => props.active && `
        span {
          opacity: 0;
        }
      `}
    }
  `
