import React from 'react'

import PageHeader from 'commons/PageHeader'
import projectsData from '.data/projects'

import List from './List'

const Projects = () => {
  return <React.Fragment>
    <PageHeader title='Projects' subTitle='My Work' />
    <List data={projectsData} />
  </React.Fragment>
}

export default Projects
