import styled from 'styled-components'

import { colors } from 'theme'

const
  secondary = `
    background: ${colors.secondary};
    border-color: ${colors.secondary};
    &:hover { color: ${colors.seondary}; }
  `,
  plainBorder = `
    border-color: ${colors.primary};
    background: transparent;
    color: ${colors.primary};
    &:hover {
      background: ${colors.primary};
      color: #fff;
    }
  `,
  plain = `
    color: ${colors.primary} !important;
    background: transparent !important;
    border: none !important;
    &:hover { opacity: 0.7; }
  `

export default styled.button`
  background: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 0 20px;
  cursor: pointer;
  font-size: 14px;
  border: none;
  font-weight: ${props => props.fontWeight || 600};
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: color 0.3s ease, background 0.3s ease, opacity 0.3s ease;
  border: ${props => props.border || 2}px solid ${colors.primary};
  color: #fff;
  &:focus { outline: none; }
  &:disabled { opacity: 0.7 !important; cursor: no-drop !important; }
  &:hover { background-color: #fff; color: ${colors.primary}; }
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  height: ${props => `${props.height || 50}px`};
  ${props => props.secondary && secondary}
  ${props => props.plainBorder && plainBorder}
  ${props => props.plain && plain}
  ${props => props.color && `color: ${props.color} !important;`}
`
