import React from 'react'
import styled from 'styled-components'

import { Section, Container } from 'commons/styles'
import { colors } from 'theme'

import Item from './Item'

const List = ({ data = [] }) => <Section>
  <Container>
    <Title>Here are few of my recent works.</Title>
    <ListWrapper>
      {data.map(i => <Item {...i}/>)}
    </ListWrapper>
  </Container>
</Section>

export default List

const 
  Title = styled.h4`
    text-align: center;
    font-size: 30px;
    color: ${colors.secondary};
    font-weight: 400;
    text-transform: uppercase;
  `,
  ListWrapper = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 50px;
    @media(min-width: 991px) {
      margin-top: 100px;
    }
  `
