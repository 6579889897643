import styled from 'styled-components'

export const 
  Container = styled.div`
    width: 100%;
    margin: 0 auto;
    max-width: ${props => props.fullWidth ? '100%' : props.maxWidth ? `${props.maxWidth}px` : '1440px'};
    padding: 0 20px;
    @media (min-width: 991px) {
      padding: 0 30px;
    }
  `,
  Row = styled.div`
    margin-left: -20px;
    margin-right: -20px;
    @media (min-width: 991px) {
      margin-left: -30px;
      margin-right: -30px;
    }
  `,
  Section = styled.div`
    padding: 40px 0;
    background: url(${props => props.image}) no-repeat center center ${props => props.bgColor || 'transparent'};
    background-size: cover;
    @media(min-width: 991px) {
      padding: 100px 0;
    }
  `