import React from 'react'
import styled from 'styled-components'

import LoaderGIF from 'assets/images/loader.gif'

import BrandLogo from 'commons/BrandLogo'

const Loader = ({ overlayParent }) => <Wrapper overlayParent={overlayParent}>
  <BrandLogo className='logo' width={70} height={80} />
  <img className='gif' src={LoaderGIF} alt='loader' />
</Wrapper>

export default Loader

const 
  Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .logo {
      margin-bottom: -50px;
      width: 70px;
    }
    .gif {
      width: 150px;
    }
    ${props => props.overlayParent && `
      position: absolute;
      background: rgba(255, 255, 255, 0.7);
      z-index: 1;
    `}
  `