import React, { useState } from 'react'
import { get } from 'lodash'
import emailjs from 'emailjs-com'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReactGA from 'react-ga'

import { Row } from 'commons/styles'
import { Form, FormGroup, FormRow } from 'commons/Form'
import Loader from 'commons/Loader'

import { node_env } from 'constants/config'

import { emailjs_service_id, emailjs_template_id, emailjs_user_id } from 'constants/config'

const ContactForm = () => {
  const [loading, updateLoader] = useState(false)
  const history = useHistory()

  const handleSubmit = e => {
    e.preventDefault()

    updateLoader(true)
    if(get(e, 'target.elements.full_name.value') && get(e, 'target.elements.email.value') && get(e, 'target.elements.message.value')) {
      emailjs.sendForm(emailjs_service_id, emailjs_template_id, e.target, emailjs_user_id)
      .then(() => {
        toast.success('Successfully Submitted.')
        if (node_env !== 'development') ReactGA.event({
            category: 'goal',
            action: 'complete_contact_form',
            label: 'contact',
            value: 1
          })
        history.push('/success')
      }, () => {
        toast.error('Error while submitting message.')
        updateLoader(false)
      })
    } else {
      toast.error('Be sure to fill all those fields.')
      updateLoader(false)
    }
  }

  return <React.Fragment>
    {loading && <Loader overlayParent />}
    <Row style={{ position: 'relative' }}>
      <Form onSubmit={handleSubmit}>
        <FormRow>
          <FormGroup>
            <label>Full Name: *</label>
            <input type="text" name="full_name" />
          </FormGroup>
          <FormGroup>
            <label>Email Address: *</label>
            <input type="email" name="email"/>
          </FormGroup>
        </FormRow>
        <FormGroup>
          <label>Your Message: *</label>
          <textarea name="message"/>
        </FormGroup>
        <FormGroup>
          <input type="submit" value="Submit"/>
        </FormGroup>
      </Form>
    </Row>
  </React.Fragment>
}

export default ContactForm
