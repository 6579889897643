import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ReactGA from 'react-ga'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ScrollToTop from 'commons/ScrollToTop'
import Header from 'commons/Header'
import Footer from 'commons/Footer'
import Loader from 'commons/Loader'

import { node_env, google_utm_code } from 'constants/config'

import ErrorPage from './404'
import Main from './Main'
import Maintenance from './Main/Maintenance'
import Projects from './Projects/Listing'
import Project from './Projects/Detail'
import Contact from './Contact'
import Collaborate from './Collaborate'
import Success from './Success'

const App = () => {
  const [loading, updateLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => updateLoading(false), 1500)
  
    if (node_env !== 'development') {
      const url = new URL(window.location.href).searchParams;

      ReactGA.initialize(google_utm_code)

      if(window.location.protocol === 'http:') window.location.replace(window.location.href.replace('http:', 'https:'))
      
      if(url.get('source')) ReactGA.event({
        category: 'goal',
        action: 'from_source',
        label: 'source',
        value: 1
      })
    }
  }, [])

  return <React.Fragment>
    {loading && <Loader />}
    <Router>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route exact path={'/projects/:id'} component={Project} />
        <Route exact path={'/projects'} component={Projects} />
        <Route exact path={'/contact'} component={Contact} />
        <Route exact path={'/collaborate'} component={Collaborate} />
        <Route exact path={'/success'} component={Success} />
        <Route exact path='/maintenance-main' component={Main} />
        <Route exact path='/' component={Maintenance} />
        <Route path='*' component={ErrorPage} />
      </Switch>
      <Footer />
    </Router>
    <ToastContainer
      autoClose={8000}
      closeButton={false}
      transition={Slide}
      hideProgressBar
      draggable={false}
      position="top-right"
      toastClassName="toast-inner-container"
      className="toast-container"
    />
  </React.Fragment>
}

export default App
