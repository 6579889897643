import React from 'react'
import styled from 'styled-components'

import { Section, Container } from 'commons/styles'
import { colors } from 'theme'

const Resume = () => <Section>
  <Container maxWidth={768}>
    <Content>
      <h4>Interested in collaborating?</h4>
      <p>I'm always open to discussing products. Have a look at my resume.</p>
    </Content>
  </Container>
</Section>

export default Resume

const 
  Content = styled.div`

  `
