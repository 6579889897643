import React from 'react'
import { get, find, toString } from 'lodash'
import styled from 'styled-components'

import projectsData from '.data/projects'

import PageHeader from './PageHeader'
import Technologies from './Technologies'

const Project = ({ history, match }) => {
  const data = find(projectsData, i => toString(i.id) === get(match, 'params.id'))

  if(!data) {
    history.push('/projects') 
    return null
  }

  return <React.Fragment>
    <PageHeader {...data} />
    <Technologies {...data} />
    {get(data, 'banner') && <Banner banner={data.banner} />}
  </React.Fragment>
}

export default Project

const Banner = styled.section`
  background: url(${props => props.banner}) no-repeat center 80px transparent;
  background-size: cover;
  height: 600px;
  background-attachment: fixed;
`
