import styled from 'styled-components'
import { colors } from 'theme'

export const 
  Form = styled.form`
    display: flex;
    flex-direction: column;
    & > div {
      flex: 1;
    }
  `,
  FormGroup = styled.div`
    padding: 10px 20px;
    @media (min-width: 991px) {
      padding: 10px 30px;
    }
    display: flex;
    flex-direction: column;
    label {
      font-size: 16px;
      font-weight: 400;
      color: rgba(41, 51, 71, 0.65);
      margin-bottom: 5px;
    }
    input, textarea {
      width: 100%;
      padding: 10px 15px;
      border: 1px solid #E6ECF8;
      color: #141c3a;
      box-shadow: none;
      font-weight: 300;
      border-radius: 6px;
      transition: border 0.2s ease;
      &:focus {
        border-color: ${colors.primary};
        outline: none;
      }
      &[type=submit] {
        font-weight: 400;
        border: 2px solid ${colors.primary};
        cursor: pointer;
        background: ${colors.primary};
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: color 0.2s ease, background 0.2s ease;
        &:hover {
          background: #fff;
          color: ${colors.primary};
        }
      }
    }
    textarea {
      min-height: 100px;
      resize: vertical;
    }
  `,
  FormRow = styled.div`
    display: flex;
    flex-direction: column;
    & > div {
      flex: 1;
    }
    @media(min-width: 768px) {
      flex-direction: row;
    }
  `
